import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import A_TextField from "../../../Atoms/Inputs/TextFields/A_TextField";
import A_Select from "../../../Atoms/Inputs/Selects/A_Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { fieldType, valueText } from "../../../../../Utils/config/config";
import A_Autocomplete from "../../../Atoms/Inputs/Autocompletes/A_Autocomplete";
import propTypes from "prop-types";
const useStyles = makeStyles(() => ({
  tableInputcontrol: {
    minWidth: 190,
    margin: "0 !important",
    padding: "0 !important",
  },
  inputcontrolTextField: {
    padding: "0.4rem 0.2rem !important",
    marginTop: "7px",
    " underline:before": {
      borderBottom: "none !important",
    },
  },

  textColor: {
    paddingTop: "21px",
    paddingBottom: "30px",
    color: "#0000008a",
    fontWeight: 500,
    fontSize: "1.30rem",
  },
  table: {
    width: "100% !important",
  },
  HeadCss: { padding: "0.6rem !important", textAlign: "center !important" },
  fieldCss: {
    padding: "0 0.8rem 0 0 !important",
    borderBottom: "none !important",
  },
  dropDownCss: { padding: "0 !important", margin: "0 !important" },
}));

const M_TabularField = (props) => {
  let formData = props.editTabular ? props.formData : props.lastFormData;

  const classes = useStyles();
  const getOptions = (optionSetId) => {
    let options = [];
    let currentOptionSet = props.optionSets.filter((optionSet) => {
      return optionSet.Id == optionSetId;
    });
    if (currentOptionSet.length > 0) {
      options = currentOptionSet[0].options;
    }
    return options;
  };
  //TODO- Following function has a future use so commenting this function
  // function calculativeFunction(calData) {
  //   var calArray = [];
  //   for (const item of calData) {
  //     if (Object.prototype.toString.call(item) === "[object Array]") {
  //       calArray.push(calculativeFunction(item));
  //     } else {
  //       if (typeof formData[item] == "undefined") {
  //         calArray.push(item);
  //       } else {
  //         calArray.push(parseFloat(formData[item]));
  //       }
  //     }
  //   }

  //   return calArray;
  // }
  // let finalValues = {};

  //setting default values
  if (formData) {
    props.elementProps.defaultValue =
      formData[props.elementProps.id] === undefined
        ? ""
        : formData[props.elementProps.id];
    props.elementProps.value =
      formData[props.elementProps.id] === undefined
        ? ""
        : formData[props.elementProps.id];
  }

  return (
    <TableContainer>
      <Typography
        className={classes.textColor}
        variant="h5"
        color="textSecondary"
      >
        {props.elementProps.label}
      </Typography>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {props.elementProps.head.map((columnHead, index) => (
              <TableCell key={index} className={classes.HeadCss}>
                {columnHead}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {props.elementProps.rows &&
            props.elementProps.rows.map((item, index) => {
              return (
                <TableRow key={`row_${index}`}>
                  {item &&
                    item.fields.map((arrayItem) => {
                      return (
                        <TableCell
                         key={formData && formData[arrayItem && arrayItem.id]}
                          component="th"
                          scope="row"
                          className={classes.fieldCss}
                          colSpan={
                            item.fields.length == 2 &&
                            arrayItem.fieldType != "text" &&
                            2
                          }
                        >
                          {arrayItem.fieldType == "string" ? (
                            <A_TextField
                              error={
                                arrayItem &&
                                arrayItem.helperText &&
                                arrayItem.helperText[0]
                              }
                              helperText={
                                arrayItem &&
                                arrayItem.helperText &&
                                arrayItem.helperText[0] == true &&
                                arrayItem.helperText[1]
                              }
                              id={arrayItem && arrayItem.id}
                              required={arrayItem && arrayItem.required}
                              label=" "
                              className={classes.inputcontrolTextField}
                              variant="standard"
                              inputProps={{ style: { textAlign: "end" } }}
                              disabled={
                                arrayItem.calculative != undefined
                                  ? true
                                  : false
                              }
                              fullWidth={true}
                              //label={arrayItem.name}
                              value={
                                formData[arrayItem && arrayItem.name]
                                  ? formData[arrayItem && arrayItem.name]
                                  : ""
                              }
                              defaultValue={
                                formData[arrayItem && arrayItem.name]
                                  ? formData[arrayItem && arrayItem.name]
                                  : ""
                              }
                              inputType= {valueText.VALUE}
                              onChange={function (e) {
                                props.handleTabularTableField(
                                  e,
                                  arrayItem && arrayItem
                                );
                                arrayItem.helperText[0] = false;
                              }}
                            />
                          ) : (
                            ""
                          )}
                          {arrayItem.fieldType == "integer" ? (
                            <A_TextField
                              error={
                                arrayItem &&
                                arrayItem.helperText &&
                                arrayItem.helperText[0]
                              }
                              helperText={
                                arrayItem &&
                                arrayItem.helperText &&
                                arrayItem.helperText[0] == true &&
                                arrayItem.helperText[1]
                              }
                              id={arrayItem && arrayItem.id}
                              label=" "
                              required={arrayItem && arrayItem.required}
                              className={classes.inputcontrolTextField}
                              variant="standard"
                              fullWidth={true}
                              inputProps={{ style: { textAlign: "end" } }}
                              disabled={
                                arrayItem.calculative != undefined
                                  ? true
                                  : false
                              }
                              //label={arrayItem.name}
                              value={
                                formData[arrayItem && arrayItem.name]
                                  ? formData[arrayItem && arrayItem.name]
                                  : ""
                              }
                              defaultValue={
                                formData[arrayItem && arrayItem.name]
                                  ? formData[arrayItem && arrayItem.name]
                                  : ""
                              }
                              onChange={function (e) {
                                props.handleTabularTableField(
                                  e,
                                  arrayItem && arrayItem
                                );
                                arrayItem.helperText[0] = false;
                              }}
                            />
                          ) : (
                            ""
                          )}
                          {arrayItem.fieldType == "float" ? (
                            <A_TextField
                              error={
                                arrayItem &&
                                arrayItem.helperText &&
                                arrayItem &&
                                arrayItem.helperText[0]
                              }
                              helperText={
                                arrayItem &&
                                arrayItem.helperText &&
                                arrayItem.helperText[0] == true &&
                                arrayItem.helperText[1]
                              }
                              id={arrayItem && arrayItem.id}
                              label=" "
                              required={arrayItem && arrayItem.required}
                              className={classes.inputcontrolTextField}
                              variant="standard"
                              inputProps={{ style: { textAlign: "end" } }}
                              disabled={
                                arrayItem && arrayItem.calculative != undefined
                                  ? true
                                  : false
                              }
                              //label={arrayItem.name}
                              type="number"
                              fullWidth={true}
                              value={
                                formData[arrayItem && arrayItem.name]
                                  ? formData[arrayItem && arrayItem.name]
                                  : ""
                              }
                              defaultValue={
                                formData[arrayItem && arrayItem.name]
                                  ? formData[arrayItem && arrayItem.name]
                                  : ""
                              }
                              onChange={function (e) {
                                props.handleTabularTableField(
                                  e,
                                  arrayItem && arrayItem
                                );
                                arrayItem.helperText[0] = false;
                              }}
                            />
                          ) : (
                            ""
                          )}
                          {arrayItem.fieldType == "text" ? (
                            <>
                              <Typography
                                error={
                                  arrayItem &&
                                  arrayItem.helperText &&
                                  arrayItem.helperText[0]
                                }
                                helperText={
                                  arrayItem &&
                                  arrayItem.helperText &&
                                  arrayItem.helperText[0] == true &&
                                  arrayItem.helperText[1]
                                }
                                label=" "
                                id={arrayItem && arrayItem.id}
                                required={arrayItem && arrayItem.required}
                              >
                                {arrayItem && arrayItem.name}
                              </Typography>
                            </>
                          ) : (
                            ""
                          )}
                          {arrayItem.fieldType == "optionset" ? (
                            <A_Select
                              error={
                                arrayItem &&
                                arrayItem.helperText &&
                                arrayItem.helperText[0]
                              }
                              helperText={
                                arrayItem &&
                                arrayItem.helperText &&
                                arrayItem.helperText[0] == true &&
                                arrayItem.helperText[1]
                              }
                              id={arrayItem && arrayItem.id}
                              required={arrayItem && arrayItem.required}
                              className={classes.tableInputcontrol}
                              variant="standard"
                              fullWidth={true}
                              inputProps={{ style: { textAlign: "center" } }}
                              native={true}
                              defaultValue={arrayItem && arrayItem.name}
                              options={getOptions(
                                arrayItem && arrayItem.optionSet
                              )}
                              onChange={function (e) {
                                props.handleTabularTableField(
                                  e,
                                  arrayItem && arrayItem.name
                                );
                                arrayItem.helperText[0] = false;
                              }}
                            />
                          ) : (
                            ""
                          )}
                          {arrayItem.fieldType == fieldType.LOOKUP ? (
                            <A_Autocomplete
                              error={
                                arrayItem &&
                                arrayItem.helperText &&
                                arrayItem.helperText[0]
                              }
                              id={arrayItem && arrayItem.id}
                              required={
                                arrayItem &&
                                arrayItem.helperText &&
                                arrayItem.helperText[0] === true
                                  ? arrayItem.required
                                  : false
                              }
                              managePage={props.managePage}
                              loogedInUserValue={
                                arrayItem.lookupdefault == true ? true : false
                              }
                              entity={props.entity}
                              variant="standard"
                              className={classes.tableInputcontrol}
                              label=" "
                              onChange={function (element, id, tabular) {
                                props.handleLookup(element, id, tabular);
                                if (
                                  arrayItem &&
                                  arrayItem.helperText &&
                                  Array.isArray(arrayItem.helperText) &&
                                  arrayItem.helperText[0]
                                ) {
                                  arrayItem.helperText[0] = false;
                                }
                              }}
                              tabular={true}
                              defaultValue={formData[arrayItem && arrayItem.id]}
                              {...arrayItem}
                            />
                          ) : (
                            ""
                          )}
                        </TableCell>
                      );
                    })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
M_TabularField.propTypes = {
  editTabular: propTypes.any,
  formData: propTypes.object,
  lastFormData: propTypes.object,
  optionSets: propTypes.any,
  elementProps: propTypes.object,
  handleTabularTableField: propTypes.any,
  managePage: propTypes.any,
  entity: propTypes.any,
  handleLookup: propTypes.any,
};
export default M_TabularField;
