/* eslint-disable react/prop-types */
import * as React from "react";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import { Box } from "@material-ui/core";
import { HistoryRounded } from "@material-ui/icons";
import { ArrowForward, ArrowBack, Close } from "@material-ui/icons";
import * as httpCall from "../../../../../Utils/apis/apis";
import { useParams } from "react-router";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import propTypes from "prop-types";
import A_Divider from "../../../atoms/dataDisplay/dividers/A_Divider";
import { makeStyles } from "@material-ui/core/styles";
import { dealstatusEnums } from "./dealStatus.Helper";

const useStyles = makeStyles(() => ({
  Button: {
    margin: "0.3rem 0",
  },
  box: {
    display: "flex",
    padding: "1.2rem 0",
    flexWrap: "wrap",
  },
  maindiv: {
    paddingTop: "20px",
  },
}));
export default function O_DealStatus(props) {
  const classes = useStyles();
  const [preButton, setPre] = React.useState();
  const [nextButton, setNext] = React.useState();
  const { crmId, pageUrl } = useParams();
  const [killPopup, setKillPopup] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [disable, setDisable] = React.useState(false);

  React.useLayoutEffect(() => {
    setButton();
  }, []);

  const getData = async () => {
    const response = await httpCall.httpGet(
      `/DealChecklist/GetDealChecklist/${crmId}?WorkFlow=Closing`
    );
    let responseData = await response;

    for (var i = 0; i < responseData.length; i++) {
      if (
        responseData[i].dealChecklist &&
        responseData[i].dealChecklist.checklistCompleted === false &&
        props.data.currentStatus == dealstatusEnums.COMPLETION
      ) {
        return true;
      }
    }
    return false;
  };

  let updateStatus = async (d, status) => {
    setDisable(true);
    let payload = {
      id: d,
      currentStatus: status,
    };
    await httpCall.httpPost(
      `/CRMData/updateDealStatus?pageUrl=${pageUrl}`,
      payload
    );
    if (status.toLowerCase() == "completion") {
      const response = await httpCall.httpGet(
        `/DealChecklist/GetDealChecklist/${crmId}?WorkFlow=Closing`
      );
      let responseData = await response;
      if (responseData.length > 0) {
        window.location.reload();
        return;
      }
      let newPayload = {
        dealId: crmId,
        dealWorkFlowState: "closing",
        action: "insert",
      };
      await httpCall.httpPost(`/DealChecklist/Insert`, newPayload);
    }
    window.location.reload(false);
  };

  let setButton = async () => {
    var isDisabled = false;
    if (props.data.currentStatus == dealstatusEnums.COMPLETION) {
      isDisabled = await getData();
    }
    let payload =
      props.data && props.data.statusPipeline ? props.data.statusPipeline : "";
    let response = await httpCall.httpGet(
      `/Common/GetDealWorkFlow?WorkFlowID=${payload}`
    );
    let pipelineData = response && response.items ? response.items : [];
    setData(pipelineData);
    for (let i in pipelineData) {
      if (
        pipelineData[i].state &&
        props.data &&
        props.data.currentStatus &&
        props.data.currentStatus == pipelineData[i].state
      ) {
        if (i > 0) {
          setPre({
            state: pipelineData[i - 1].state,
          });
        }
        if (pipelineData && i < pipelineData.length) {
          let d = +i + 1;
          setNext({ state: pipelineData[d].state, isDisabled: isDisabled });
        }
        break;
      }
    }
  };
  const renderConfirmPopup = () => {
    return (
      <div>
        <div className={classes.maindiv}>
          <A_Button
            onClick={() => {
              updateStatus(crmId, dealstatusEnums.KILLED);
            }}
            color="primary"
            label="Yes"
            // disabled={downloading}
          />
          <A_Button onClick={handleKillPopup} color="default" label="Close" />
        </div>
      </div>
    );
  };
  let handleKillPopup = () => {
    setDisable(true);
    let data = !killPopup;
    setKillPopup(data);
    setDisable(false);
  };
  return (
    <Box>
      <Box className={classes.box}>
        {/* {data.map} */}
        {props.data &&
        props.data.currentStatus &&
        (props.data.currentStatus == dealstatusEnums.TRACKING ||
          (props.data.currentStatus === props.initialStage
            ? props.initialStage
            : "")) &&
        data &&
        data.length > 0 ? (
          <A_Button
            className={classes.Button}
            onClick={() => {
              setDisable(true), updateStatus(crmId, data[0].state);
            }}
            endIcon={<ArrowForward />}
            label={data[0].state}
            disabled={disable}
            color="primary"
          />
        ) : props.data &&
          props.data.currentStatus &&
          props.data.currentStatus != dealstatusEnums.KILLED &&
          props.data.currentStatus != dealstatusEnums.DEAD ? (
          <A_Button
            className={classes.Button}
            onClick={() => {
              setDisable(true), updateStatus(crmId, dealstatusEnums.TRACKING);
            }}
            startIcon={<HistoryRounded />}
            label={props.initialStage ? props.initialStage : dealstatusEnums.TRACKING}
            disabled={disable}
          />
        ) : (
          ""
        )}
        {preButton ? (
          <A_Button
            disabled={disable}
            className={classes.Button}
            onClick={() => {
              setDisable(true), updateStatus(crmId, preButton.state);
            }}
            startIcon={<ArrowBack />}
            label={preButton.state ? preButton.state : ""}
            color="primary"
          />
        ) : (
          ""
        )}
        {nextButton ? (
          <A_Button
            className={classes.Button}
            onClick={() => {
              setDisable(true),
                updateStatus(crmId, nextButton.state ? nextButton.state : "");
            }}
            endIcon={<ArrowForward />}
            label={nextButton.state ? nextButton.state : ""}
            color="primary"
            disabled={nextButton.isDisabled || disable}
          />
        ) : (
          ""
        )}
        {(props.data &&
          props.data.currentStatus &&
          props.data.currentStatus == dealstatusEnums.KILLED) ||
        (props.data.currentStatus == dealstatusEnums.DEAD && data && data.length > 0) ? (
          <A_Button
            className={classes.Button}
            onClick={() => {
              updateStatus(crmId, data[0].state);
            }}
            disabled={disable}
            startIcon={<Close />}
            label="Revive"
          />
        ) : (
          <A_Button
            className={classes.Button}
            onClick={() => {
              handleKillPopup();
            }}
            disabled={disable}
            startIcon={<Close />}
            label="Kill"
          />
        )}
      </Box>
      <A_Divider></A_Divider>
      <A_SimpleDialog
        open={killPopup}
        title={dealstatusEnums.COMFIRM_KILL}
        fullScreen={false}
        height="400px"
        fullwidth={true}
        maxWidth={"xs"}
        onClose={handleKillPopup}
        closePopUp={handleKillPopup}
        dialogContent={renderConfirmPopup()}
      />
    </Box>
  );
}

O_DealStatus.propTypes = {
  data: propTypes.object,
};
