 
import { fieldType } from "../../../../../../Utils/config/fieldType";
import * as ManageEntityHelper from "./M_ManageEntityForm.helper";
import { httpPut } from "../../../../../../Utils/apis/apis";
import { handleFileUpload } from "../../File Upload/M_fileUpload.helper";
import { cardType } from "../../../../../../Utils/config/cardType";
import edgeUpdate from "./edgeUpdate";
import { addEdge } from "./../../../../../../Utils/config/config";
export const handleUpdate = async (
  editFormData,
  props,
  pageUrl,
  crmId,
  fileUploadConfig,
  user,
  labelName,
  submitToStoredProc
) => {
  try {
    const { card, edgeId } = props;
    let result = null;
     let newFormData = { ...editFormData, parentId: crmId };
    var filelist = [];

    for (let i in newFormData) {
      if (
        newFormData[i] &&
        newFormData[i].fieldType &&
        newFormData[i].fieldType == fieldType.DOCUMENT
      ) {
        filelist.push(newFormData[i]);
        newFormData[i] = undefined;
      }
    }
    let security = { ...newFormData.security };
    let edge = ManageEntityHelper.setEdgeLabelData(newFormData);
    try {
      if (pageUrl === labelName.MEETING) {
        for (let i = 0; i < edge.length; i++) {
          if (edge[i].label == "organiser") {
            edge[i].label = "attendedBy";
            edge[i].twoWayLabel = "attended";
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
    delete newFormData.security;
    let convertedFormData = {
      id: props.IRCRecordId
        ? props.IRCRecordId
        : props.entity.recordId !== ""
        ? props.entity.recordId
        : crmId,
      label: pageUrl,
      type: "vertex",
      properties: newFormData,
      security: security,
      edges: edge,
      submitToStoredProc,
    };
    let response = "";
    if (props.entity && props.entity.hasContainer) {
      if (
        props.entity &&
        props.entity.hasContainer &&
        props.entity.updatePayloadDocumentDb &&
        props.entity.hasContainer === props.entity.updatePayloadDocumentDb
      ) {
        props.entity.formData =
          props.managePage &&
          props.managePage.data &&
          props.managePage.data.data;
        await ManageEntityHelper.schedulerCustomPayload(
          convertedFormData,
          crmId,
          props.entity
        );
      }
      response = await httpPut(
        `/GenericDocument/${props.entity.hasContainer}/${convertedFormData.id}/update`,
        newFormData
      );
    } else {
      switch (card) {
        case cardType.DEAL_TEAM:
          response = await edgeUpdate(card, edgeId, convertedFormData);
          break;
        default:
          response = await httpPut(`/CRMData/${"update"}`, convertedFormData);
          break;
      }
    }
    let fileName = response.id + "/";
    if (filelist && filelist.length > 0) {
      handleFileUpload(
        "",
        response.id,
        user,
        filelist,
        fileUploadConfig,
        fileName,
        "",
        "",
        false,
        !props.refresh,
        false
      );
    }
    //TODO - checking message in response does not make any sense, refactor it
    if ("message" in response) {
      result = {
        status: false,
        result: response,
      };
    } else {
      if ("id" in response) {
        result = {
          status: true,
          result: response,
        };
      }
    }
    let payload = {
      sourceVertexId:
        convertedFormData &&
        convertedFormData.edges &&
        convertedFormData.edges.length > 0 &&
        convertedFormData.edges[0].guid &&
        convertedFormData.edges[0].guid.length > 0
          ? convertedFormData.edges[0].guid[0]
          : "",
      destinationVertexId: convertedFormData.id,
      edgeLabel:
        convertedFormData &&
        convertedFormData.edges &&
        convertedFormData.edges.length > 0 &&
        convertedFormData.edges[0].label
          ? convertedFormData.edges[0].label
          : "",
    };
    switch (props.addEdge) {
      case addEdge.NOTREQUIRED:
        break;
      default:
        payload.sourceVertexId &&
          payload.destinationVertexId &&
          payload.edgeLabel &&
          // TODO - Update should be through SAGA
          (await httpPut(`/CRMData/addEdge`, payload));
    }
    return result;
  } catch (e) {
    //TODO - Implement logging to log all the errors DB label
    console.log("Error in hadleUpdate ", e);
    throw e;
  }
};
