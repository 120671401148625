import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Grid from "@material-ui/core/Grid";
import A_Select from "../../../../../../Atoms/Inputs/Selects/A_Select";
import * as helpers from "../../../../../../../../Utils/Helpers/chartHelpers";
import * as common from "../../../../../../../../Utils/Helpers/common";
import Funnel from "highcharts/modules/funnel";
import solidGauge from "highcharts/modules/solid-gauge";
import HighchartsHeatmap from "highcharts/modules/heatmap";
import Treemap from "highcharts/modules/treemap";
import HighchartsMore from "highcharts/highcharts-more";
import Popper from "@material-ui/core/Popper";
import { Tooltip } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ViewListIcon from "@material-ui/icons/ViewList";
import * as commonFunctions from "../../../../../../../../Utils/Helpers/common";
import A_Paper from "../../../../../../Atoms/Surfaces/Papers/A_Paper";
import { httpPost } from "../../../../../../../../Utils/apis/apis";

function ListItemLink(props) {
  return props.tooltip ? (
    <Tooltip title={props.tooltip} arrow>
      <ListItem button component="a" {...props} />
    </Tooltip>
  ) : (
    <ListItem button component="a" {...props} />
  );
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    width: "100%",
    marginLeft: "12px",
  },
  DropdownHeight: {
    height: "3rem",
  },
  popUpIcon: {
    float: "right",
  },
  main: {
    height: "100vh",
  },
  NoData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "500",
    fontSize: "smaller",
  },
}));

function executeJS(obj) {
  return Function(
    " return (function(configs,chartData,helpers,chartParameters){let newConfig=configs; " +
      obj +
      " console.log('chart data:', chartData); if(newConfig.series==='chartData'){newConfig.series=chartData;}; return newConfig; })"
  )();
}

const M_GraphChart = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  let currTheme = common.getTheme();
  Highcharts.setOptions(currTheme.chartTheme);
  Funnel(Highcharts);
  HighchartsMore(Highcharts);
  solidGauge(Highcharts);
  HighchartsHeatmap(Highcharts);
  Treemap(Highcharts);
  const classes = useStyles();
  let { configuration, javaScript } = props.chart.chartConfiguration;
  let data = props.chart.data;
  let graphData = JSON.parse(JSON.stringify(data));
  let configurationRaw = JSON.parse(configuration);
  let chartParameters =
    props && props.chartRequest && props.chartRequest.parameters
      ? props.chartRequest.parameters
      : {};
  let chartOptions = executeJS(javaScript)(
    configurationRaw,
    graphData,
    helpers,
    chartParameters
  );
  if (
    currTheme.chartTheme &&
    currTheme.chartTheme.chart &&
    currTheme.chartTheme.chart.credits
  ) {
    chartOptions.credits =
      currTheme.chartTheme &&
      currTheme.chartTheme.charts &&
      currTheme.chartTheme.charts.credits;
  }
  let filters = [];
  if ("filters" in chartOptions) {
    filters = chartOptions.filters;
  }

  const handleExcelDownload = async () => {
    setAnchorEl(null);

    let convertedFormData = [
      {
        chartId: props.chartRequest.chartId || null,
        parameters: props.chartRequest.parameters || {},
        content: props.chartRequest.content || {},
        fileName: "excelFile",
      },
    ];
    let config = commonFunctions.getHttpConfig();
    const response = await httpPost(
      `/ChartData/download-excel`,
      convertedFormData,
      { ...config, responseType: "blob" }
    );
    let cardTitle =
      props.chart && props.chart.chartConfiguration
        ? props.chart.chartConfiguration.chartName + ".xlsx"
        : "excelFile.xlsx";
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", cardTitle);
    link.click();
  };

  // const handleCardMenu = (event) => {
  //   setAnchorEl(anchorEl !== null ? null : event.currentTarget);
  // };
  const handleFilters = (selectedValue, id) => {
    let chartRequest = props.chartRequest;
    if (
      chartRequest &&
      chartRequest.parameters &&
      id in chartRequest.parameters
    ) {
      let newParameters = chartRequest.parameters;
      newParameters[id] = selectedValue.value;
      let newChartRequest = {
        chartId: chartRequest.chartId,
        parameters: newParameters,
      };
      props.refreshChart(newChartRequest);
    } else {
      console.log(
        "parameter not found in chart request for chart: ",
        chartOptions.chartName
      );
    }
    //props.handleFilters();
  };
  const getDefaultValue = (filter) => {
    let defaultValue = "";
    if (filter.value) {
      defaultValue = filter.value;
    } else if (props.chartRequest && props.chartRequest.parameters) {
      defaultValue = props.chartRequest.parameters[filter.parameter];
    }
    return defaultValue;
  };
  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <Grid container justify="center" spacing={2}>
          {filters.map((filter) => (
            <Grid item key={filter}>
              <A_Select
                className={classes.DropdownHeight}
                id={filter.parameter}
                label={filter.label}
                options={filter.options}
                defaultValue={getDefaultValue(filter)}
                onChange={handleFilters}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {/* {props.header.secondaryMenu && ( */}

        {/* )} */}
        <Popper
          className={classes.paper}
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement="bottom-end"
        >
          <A_Paper
            width={25}
            height={20}
            content={
              <List component="nav" aria-label="card menu">
                <ListItemLink onClick={handleExcelDownload} tooltip="Excel">
                  <ListItemIcon>
                    <ViewListIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Excel" />
                </ListItemLink>
              </List>
            }
            size="small"
          />
        </Popper>
        {graphData && Object.keys(graphData).length !== 0 ? (
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        ) : (
          <div className={classes.NoData}>
            <p>WE ARE SORRY, NO CONTENT FOUND!</p>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

M_GraphChart.defaultProps = {
  options: {
    title: {
      text: "Default Chart",
    },
    series: [
      {
        data: [1, 2, 3, 4, 5],
      },
    ],
  },
};
M_GraphChart.propTypes = {
  chart: PropTypes.any,
  chartRequest: PropTypes.any,
  refreshChart: PropTypes.any,
};
ListItemLink.propTypes = {
  tooltip: PropTypes.any,
};
export default M_GraphChart;
