export const rgbtoHex = (value) => {
  let htmlString = value;

  let regex = /rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/g;

  // Function to convert RGB to hex color
  function rgbConverter(r, g, b) {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }

  // Replace RGB color with hex color in the HTML string
  htmlString = htmlString.replace(regex, (match, r, g, b) => {
    let hexColor = rgbConverter(parseInt(r), parseInt(g), parseInt(b));
    return `${hexColor}`;
  });

  return htmlString;
};
