import React from "react";
import PropTypes from "prop-types";
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css";
//www.npmjs.com/package/react-summernote
import "bootstrap/js/dist/modal";
import * as injectJavascript from "./summernoteHelper";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/tooltip";
import "bootstrap/dist/css/bootstrap.css";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  labelSummerNote: {
    padding: 0,
    fontSize: "1rem",
    fontFamily: "inherit",
    fontWeight: 400,
    lineHeight: 1,
    marginTop: "16px",
    marginBottom: "10px",
  },
  reactSummernote: {
    color: "black !important",
    "& p": { marginBottom: 0, color: "black !important" },
    "& .note-btn.dropdown-toggle::after": {
      display: "none",
      color: "black !important",
    },
  },
}));
const A_SummerNote = (props) => {
  const inputFile = React.useRef(null);
  const classes = useStyles();
  let keyIndicator = "";
  let validateKeypress = false;
  const [alertWarning, setalertWarning] = React.useState(
    `This field has a Character limit : ${
      props && props.inputMaxLength && Number(props.inputMaxLength)
    } , Exceeding characters will be trimmed`
  );
  function onBlurHandler() {
    props &&
      props.inputMaxLength &&
      Number(keyIndicator) === Number(props.inputMaxLength) &&
      onChangeHandler("", props);
  }

  function handleKeyPress(ev) {
    if (props && Number(props.inputMaxLength) === 0) {
      ev.preventDefault();
    }
    //  if(props && props.inputMaxLength) {validateKeypress=true};
    //   if(props && props.inputMaxLength&&validateKeypress===true){viewValsetter=ev.currentTarget.innerText;};
    if (
      props &&
      props.inputMaxLength &&
      Number(keyIndicator) <= 0 &&
      ev.currentTarget.innerHTML.replace(/(<([^>]+)>)/gi, "") == ""
    ) {
      return true;
    }
    props &&
      props.inputMaxLength &&
      Number(keyIndicator) === 0 &&
      ev.key != "Backspace" &&
      ev.preventDefault();
    props &&
      props.inputMaxLength &&
      Number(keyIndicator) < 0 &&
      ev.key != "Backspace" &&
      ev.preventDefault();
    if (
      props &&
      props.inputMaxLength &&
      Number(keyIndicator) > 0 &&
      ev.key == "Backspace"
    ) {
      ev.returnValue = true;
    }
  }
  React.useEffect(() => {
    onChangeHandler(
      props &&
        props.inputMaxLength &&
        Number(props.inputMaxLength) &&
        props.defaultValue != ""
        ? props.defaultValue
        : "",
      props
    );
  }, [props.id]);
  function extractContent(s) {
    var span = document.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }

  const onChangeHandler = (value, props) => {
    const hexVal = injectJavascript.rgbtoHex(value);
    let regexValueRemove = extractContent(hexVal);
    props &&
      props.inputMaxLength &&
      regexValueRemove.length === 0 &&
      setalertWarning(
        `You have ${props.inputMaxLength} characters remaining , Maximum character limit : ${props.inputMaxLength}`
      );
    regexValueRemove =
      props &&
      props.inputMaxLength &&
      regexValueRemove &&
      regexValueRemove.includes("&amp")
        ? regexValueRemove.split("amp;").join("")
        : regexValueRemove;
    regexValueRemove =
      props &&
      props.inputMaxLength &&
      regexValueRemove &&
      regexValueRemove.includes("&gt")
        ? regexValueRemove.split("gt;").join("")
        : regexValueRemove;
    regexValueRemove =
      props &&
      props.inputMaxLength &&
      regexValueRemove &&
      regexValueRemove.includes("&lt")
        ? regexValueRemove.split("lt;").join("")
        : regexValueRemove;
    regexValueRemove =
      props &&
      props.inputMaxLength &&
      regexValueRemove &&
      regexValueRemove.includes("&apos")
        ? regexValueRemove.split("apos;").join("")
        : regexValueRemove;
    regexValueRemove =
      props &&
      props.inputMaxLength &&
      regexValueRemove &&
      regexValueRemove.includes("&quot")
        ? regexValueRemove.split("quot;").join("")
        : regexValueRemove;
    const textlength =
      props &&
      props.inputMaxLength &&
      regexValueRemove &&
      regexValueRemove.length;
    const inputVal = props && props.inputMaxLength && regexValueRemove;

    const valChange =
      props && props.inputMaxLength && Number(props.inputMaxLength);
    const providerVal =
      props && props.inputMaxLength && inputVal && inputVal.includes("&nbsp")
        ? props &&
          props.inputMaxLength &&
          inputVal.split("&nbsp;").join(" ").slice(0, valChange)
        : props && props.inputMaxLength && inputVal.slice(0, valChange);
    const num =
      props &&
      props.inputMaxLength &&
      inputVal &&
      inputVal.split("&nbsp;").join(" ");
    const differenceVal = props && props.inputMaxLength && num;

    props &&
      props.inputMaxLength &&
      valChange &&
      textlength &&
      typeof textlength != "string" &&
      differenceVal &&
      differenceVal.length > 0 &&
      setalertWarning(
        `You have ${
          valChange - differenceVal.length >= 0
            ? valChange - differenceVal.length
            : "no"
        } characters remaining , Maximum character limit : ${valChange}`
      );
    props &&
      props.inputMaxLength &&
      valChange &&
      textlength &&
      typeof textlength != "string" &&
      differenceVal &&
      differenceVal.length == 0 &&
      setalertWarning(
        `You have ${valChange} characters remaining , Maximum character limit : ${valChange}`
      );
    if (
      props &&
      props.inputMaxLength &&
      valChange &&
      textlength &&
      typeof textlength != "string" &&
      differenceVal
    ) {
      if (differenceVal && differenceVal.length > 0) {
        keyIndicator = valChange - differenceVal.length;
      } else if (differenceVal && differenceVal.length == 0) {
        keyIndicator = valChange;
      }
    }
    props &&
      props.inputMaxLength &&
      typeof textlength === "string" &&
      setalertWarning(
        `You have ${valChange} characters remaining , Maximum character limit : ${valChange}`
      );
    if (props && props.inputMaxLength) {
      let flag = false;
      let count = 0;
      let dataMax = "";
      //TODO Function to be enhanced to have only revlevant tag to be available in HTML state
      for (let i = 0; i < hexVal.length; i++) {
        if (hexVal[i] === "<") {
          flag = true;
          dataMax = dataMax + hexVal[i];
        } else if (!flag) {
          if (count != props.inputMaxLength) {
            dataMax = dataMax + hexVal[i];
            ++count;
          }
        } else if (hexVal[i] === ">") {
          flag = false;
          dataMax = dataMax + hexVal[i];
        } else {
          dataMax = dataMax + hexVal[i];
        }
      }
      props.onChange(
        props &&
          props.inputMaxLength &&
          inputVal &&
          providerVal &&
          validateKeypress === false &&
          dataMax,
        props.id
      );
    } else if (props && hexVal) {
      // replace font family

      props.onChange(hexVal, props.id);
    }
  };

  return (
    <>
      <label className={classes.labelSummerNote}>
        {props.required ? props.label + "*" : props.label}
      </label>
      <ReactSummernote
        disabled={props.disabled}
        tag="textarea"
        className={classes.reactSummernote}
        options={{
          height: props.height,
          dialogsInBody: true,
          dragAndDrop: true,
          dialogsFade: true,
          toolbar: [
            ["style", ["bold", "italic", "underline", "clear"]],
            ["color", ["color", "reset"]],
            [
              "image",
              [
                "image",
                "insert",
                "dragImageHere",
                "dropImage",
                "selectFromFiles",
                "url",
              ],
            ],
            ["para", ["ul", "ol", "paragraph", "listStyles"]],
            ["history", ["undo", "redo"]],
            ["table", ["table"]],
          ],
        }}
        ref={inputFile}
        onBlur={(e) => onBlurHandler(e, props)}
        onChange={(e) => onChangeHandler(e, props)}
        onKeyDown={(ev) => handleKeyPress(ev)}
      >
        {props.defaultValue}
      </ReactSummernote>
      {props && props.inputMaxLength && (
        <p style={{ fontSize: "0.75rem" }}>{alertWarning}</p>
      )}
    </>
  );
};
A_SummerNote.defaultProps = {
  label: "SummerNote",
};
A_SummerNote.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  height: PropTypes.string,
  inputMaxLength: PropTypes.any,
  id: PropTypes.any,
  required: PropTypes.any,
};
export default A_SummerNote;
