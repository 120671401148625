import React from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import A_SummerNote from "../../../Atoms/Inputs/SummerNote/A_SummerNote";
import "react-summernote/dist/react-summernote.css";
import { httpPost, httpPut } from "../../../../../Utils/apis/apis";
import { connect } from "react-redux";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import A_Snackbar from "../../../Atoms/Feedback/Snackbars/A_Snackbar";
import { makeStyles } from "@material-ui/core";
import M_FormDropableFileUpload from "../../../Molecules/Common/File Upload/M_FormDropableFileUpload";
import Grid from "@material-ui/core/Grid";
import M_RadiosGroupConfig from "../../../Molecules/Common/RadioGroup/M_RadiosGroupConfig";
import A_Autocomplete from "../../../Atoms/Inputs/Autocompletes/A_Autocomplete";
import { Box } from "@material-ui/core";
import propTypes from "prop-types";
import A_MultiSelect from "../../../Atoms/Inputs/Selects/A_MultiSelect";
import { fieldType, ircConfig } from "../../../../../Utils/config/config";
import { message } from "../../../../../Utils/config/messages";
import { emailPrefix, fileConst } from "./email.helper";
const useStyles = makeStyles(() => ({
  nextButton: { marginLeft: "220px", marginTop: "2rem", textTransform: "none" },
  sendMailButton: { marginTop: "2rem", textTransform: "none" },
  capitalize: {
    textTransform: "capitalize",
  },
  noData: {
    color: "red",
  },
  inputGroup: {
    display: "flex",
  },
  backButton: {
    marginRight: "170px",
    marginTop: "2rem",
  },
  inputField: {
    marginTop: "0.4rem",
  },
  inputLabel: {
    paddingRight: "1.2rem",
    fontWeight: "bold",
  },
  spanError: {
    color: "red",
    margin: "0",
    padding: "0 0 0 40px",
  },
  span: {
    color: "#ff6666",
  },
  inputControl: {
    margin: 0,
  },
  formGroup: {
    margin: "1rem 0",
  },
}));

function IcEmail(props) {
  const { options, content } = props;
  const [files, setFiles] = React.useState([]);
  const [formIndex, setFormIndex] = React.useState(0);
  const [formData, setFormData] = React.useState({});
  const [relatedTo, setRelatedTo] = React.useState("");
  const [remark, setRemark] = React.useState("");
  const user = useSelector((state) => state.oidc.user);
  const [validation, setValidation] = React.useState({});
  const [relatedToError, setRelatedToError] = React.useState(null);
  const classes = useStyles();
  const [changesSaved, setChangesSaved] = React.useState({
    status: false,
    type: "success",
    msg: "",
  });

  const nextPage = () => {
    if (firstPageValidation()) {
      setFormIndex(formIndex + 1);
    }
  };
  const prePage = () => {
    if (formIndex > 0) {
      setFormIndex(formIndex - 1);
    }
  };
  const handleClose = () => {
    props.onClose();
    setFormData({});
    setValidation({});
    setFormIndex(0);
    setFiles([]);
    setRelatedTo("");
    setRelatedToError(null);
    setRemark("");
  };
  const firstPageValidation = () => {
    setRelatedToError(null);
    setValidation({});
    switch (relatedTo) {
      case fileConst.PROJECT:
        return validationNullCheck(fileConst.DEAL);
      case fileConst.PORTFOLIOCOMPANY:
        return validationNullCheck(fileConst.PORTFOLIOCOMPANY);
      case fileConst.OTHER:
        return validationNullCheck(fileConst.OTHER);
      default:
        setRelatedToError(message.REQUIRED_FIELD);
        return false;
    }
  };
  const finalPageValidation = () => {
    let res = true;
    let tempValidation = { ...validation };
    if (!validationNullCheck(fileConst.RECIPIENTS)) {
      tempValidation[fileConst.RECIPIENTS] = message.REQUIRED_FIELD;
      res = false;
    } else {
      tempValidation[fileConst.RECIPIENTS] = undefined;
    }
    if (!validationNullCheck(fileConst.SUBJECT)) {
      tempValidation[fileConst.SUBJECT] = message.REQUIRED_FIELD;
      res = false;
    } else {
      tempValidation[fileConst.SUBJECT] = undefined;
    }
    setValidation(tempValidation);
    return res;
  };
  const validationNullCheck = (fieldId, formDataRecent = null) => {
    let newFormData = formDataRecent ? { ...formDataRecent } : { ...formData };
    let newValidation = { ...validation };
    if (
      newFormData[fieldId] === undefined ||
      newFormData[fieldId] === null ||
      newFormData[fieldId] === ""
    ) {
      newValidation[fieldId] = (
        <div className={classes.noData}>{message.REQUIRED_FIELD}</div>
      );
      setValidation(newValidation);
      return false;
    } else {
      newValidation[fieldId] = undefined;
      setValidation(newValidation);
      return true;
    }
  };
  const onRadioFilterChange = (selectedValue) => {
    setRelatedToError(null);
    setRelatedTo(selectedValue.value);
  };
  const handleLookup = (selectedValue, id) => {
    let newFormData = { ...formData };
    selectedValue.data != ""
      ? (newFormData[id] = { ...selectedValue, fieldType: fieldType.LOOKUP })
      : (newFormData[id] = undefined);
    setFormData(newFormData);
    let tempValidation = { ...validation };
    delete tempValidation[id];
    setValidation(tempValidation);
  };
  const handleSelect = (selectedValue, id) => {
    let newFormData = { ...formData };
    newFormData[id] = { data: selectedValue, fieldType: fieldType.OPTIONSET };
    setFormData(newFormData);
    let tempValidation = { ...validation };
    delete tempValidation[id];
    setValidation(tempValidation);
  };
  function subjectEmail(event) {
    let newFormData = { ...formData };
    newFormData[fileConst.SUBJECT] = event.target.value;
    setFormData(newFormData);
    let tempValidation = { ...validation };
    delete tempValidation[fileConst.SUBJECT];
    setValidation(tempValidation);
  }
  const handleTextField = (event) => {
    let newFormData = { ...formData };
    newFormData[event.target.id] = event.target.value;
    setFormData(newFormData);
    let tempValidation = { ...validation };
    delete tempValidation[event.target.id];
    setValidation(tempValidation);
  };
  function handleSummerNote(e) {
    setRemark(e);
  }
  const setFileData = (event, files) => {
    event.preventDefault();
    setFiles(files);
    event.preventDefault();
  };
  const uploadFile = (file, dealCrmId) => {
    let fileName = dealCrmId + "/" + file.name;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      let subdomain = new URL(window.location.href).hostname.split(".")[0];
      const url = `${process.env.DOCUMENT_HELPER}`;
      let header = {
        filename: fileName,
        type: "email",
        "file-operation": "upload",
        "process-file": "no",
        "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
        subdomain: subdomain,
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.access_token,
      };
      const newFormData = { file: e.target.result };
      axios
        .post(url, newFormData, { headers: header })

        .then(
          (response) => {
            let message =
              response && response.message
                ? response.message
                : "Uploaded successfully";
            setChangesSaved({
              status: true,
              type: "success",
              msg: message,
            });
          },
          (error) => {
            let message = error && error.message ? error.message : "Error";
            setChangesSaved({
              status: true,
              type: "error",
              msg: message,
            });
          }
        );
    };
  };
  const showResult = async () => {
    let dealCrmId =
      formData &&
      formData.deal &&
      formData.deal &&
      formData.deal.data &&
      formData.deal.data.id
        ? formData.deal.data.id
        : "0";
    for (let item in files) {
      uploadFile(files[item], dealCrmId);
    }

    if (finalPageValidation()) {
      let filesData = [];
      for (let item in files) {
        let filePath = emailPrefix + dealCrmId + "/" + files[item].name;
        filesData.push(filePath);
      }
      let toUsers = "";
      if (formData && formData.recipients) {
        if (formData.recipients.fieldType === fieldType.LOOKUP) {
          toUsers =
            formData && formData.recipients && formData.recipients.data
              ? formData.recipients.data.map((item) => item.title)
              : [];
        } else if (formData.recipients.fieldType === fieldType.OPTIONSET) {
          toUsers =
            formData && formData.recipients && formData.recipients.data
              ? formData.recipients.data.map((item) => item.value)
              : [];
        }
      }

      let ccUsers =
        formData && formData.ccUsers && formData.ccUsers.data
          ? formData.ccUsers.data.map((item) => item.title)
          : [];
      const data = {
        id: "",
        label: "ircemail",
        properties: {
          subject: formData.subject,
          from: ircConfig.EMAIL,
          recipients: JSON.stringify(toUsers),
          remark: remark,
          ccUsers: JSON.stringify(ccUsers),
          attachGUID:
            formData &&
            formData.deal &&
            formData.deal &&
            formData.deal.data &&
            formData.deal.data.id
              ? formData.deal.data.id
              : undefined,
          deal:
            formData &&
            formData.deal &&
            formData.deal.data &&
            formData.deal.data.label
              ? formData.deal.label
              : undefined,
          isMailSent: false,
          responses: "[]",
          attachments: filesData.join(","),
        },
        edges: [],
        security: {
          read: {
            groupids: [],
            users: [],
          },
          update: {
            groupids: [],
            users: [],
          },
          full: {
            groupids: [],
            users: [],
          },
          privateRecord: true,
        },
      };
      // Todo require for future development
      // var pageId = props.currentPage.pageConfig.id;
      const response = await httpPost(`/CRMData/insert`, data);

      if (response.id != null) {
        var data2 = {
          sourceVertexId:
            formData &&
            formData.deal &&
            formData.deal.data &&
            formData.deal.data.id,
          destinationVertexId: response && response.id,
          edgeLabel: "hasircemail",
        };
        await httpPut(`/CRMData/addEdge`, data2);
      }
      setChangesSaved({
        status: true,
        type: "success",
        msg: "Mail Sent Successfully",
      });
      handleClose();
    }
  };
  const handleSnackbarClose = () => {
    setChangesSaved({
      status: false,
      type: "",
      msg: "",
    });
  };

  return (
    <div>
      <A_Snackbar
        open={changesSaved.status}
        message={changesSaved.msg}
        type={changesSaved.type}
        autoHideDuration={2000}
        vertical="bottom"
        horizontal="center"
        handleClose={handleSnackbarClose}
      />
      <A_SimpleDialog
        open={props.isOpen}
        title={"Add a New email Approval"}
        maxWidth="sm"
        dialogContent={
          <div>
            {formIndex === 0 ? (
              <>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <div className=""> Relating to</div>
                    <M_RadiosGroupConfig
                      className={classes.filterSelect}
                      error={relatedToError}
                      helperText={relatedToError}
                      native={true}
                      defaultValue={relatedTo}
                      align="horizontal"
                      options={content.radioOptions ? content.radioOptions : []}
                      onChange={onRadioFilterChange}
                    />
                  </Grid>
                </Grid>
                {relatedTo === fileConst.PROJECT ? (
                  <Grid container alignItems="center">
                    <Grid item xs={12}>
                      <A_Autocomplete
                        size="medium"
                        entity={{}}
                        variant="standard"
                        className={classes.inputControl}
                        label={"Deal *"}
                        onChange={handleLookup}
                        id={fileConst.DEAL}
                        fullWidth={true}
                        error={
                          validation[fileConst.DEAL] === undefined
                            ? false
                            : true
                        }
                        helperText={
                          validation[fileConst.DEAL]
                            ? validation[fileConst.DEAL]
                            : ""
                        }
                        edgeLabel={fileConst.DEAL}
                        resultTemplate={
                          props.content &&
                          props.content.dealLookup &&
                          props.content.dealLookup.resultTemplate
                            ? props.content.dealLookup.resultTemplate
                            : ""
                        }
                        destinationVertex={
                          props.content &&
                          props.content.dealLookup &&
                          props.content.dealLookup.destinationVertex
                            ? props.content.dealLookup.destinationVertex
                            : ""
                        }
                        defaultValue={
                          formData && formData.deal && formData.deal.data
                            ? formData.deal.data
                            : ""
                        }
                        loogedInUserValue={true}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}
                {relatedTo === fileConst.PORTFOLIOCOMPANY ? (
                  <Grid container alignItems="center">
                    <Grid item xs={12} className={classes.formGroup}>
                      <TextField
                        className={classes.inputField}
                        id={fileConst.PORTFOLIOCOMPANY}
                        label="Company "
                        fullWidth={true}
                        error={
                          validation[fileConst.PORTFOLIOCOMPANY] !==
                            undefined &&
                          validation[fileConst.PORTFOLIOCOMPANY] !== ""
                        }
                        helperText={
                          validation[fileConst.PORTFOLIOCOMPANY]
                            ? validation[fileConst.PORTFOLIOCOMPANY]
                            : ""
                        }
                        placeholder=""
                        variant="standard"
                        onChange={handleTextField}
                        value={formData.subject}
                        required
                      />
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}
                {relatedTo === fileConst.OTHER ? (
                  <Grid container alignItems="center">
                    <Grid item xs={12} className={classes.formGroup}>
                      <TextField
                        className={classes.inputField}
                        id={fileConst.OTHER}
                        label="Other "
                        fullWidth={true}
                        error={
                          validation[fileConst.OTHER] !== undefined &&
                          validation[fileConst.OTHER] !== ""
                        }
                        helperText={
                          validation[fileConst.OTHER]
                            ? validation[fileConst.OTHER]
                            : ""
                        }
                        placeholder=""
                        variant="standard"
                        onChange={handleTextField}
                        value={formData[fileConst.OTHER]}
                        required
                      />
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}

                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyItems="end"
                >
                  <Grid item xs={12}>
                    <Box style={{ float: "right" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.nextButton}
                        type="button"
                        onClick={nextPage}
                      >
                        Next
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </>
            ) : (
              ""
            )}

            {formIndex === 1 ? (
              <>
                <Grid container alignItems="center">
                  <Grid item xs={12}>
                    {options === undefined ||
                    options === null ||
                    options === [] ? (
                      <A_Autocomplete
                        fullWidth={true}
                        size="medium"
                        entity={{}}
                        variant="standard"
                        className={classes.inputControl}
                        error={
                          validation[fileConst.RECIPIENTS] !== undefined &&
                          validation[fileConst.RECIPIENTS] !== ""
                        }
                        helperText={
                          validation[fileConst.RECIPIENTS]
                            ? validation[fileConst.RECIPIENTS]
                            : ""
                        }
                        label={"To *"}
                        onChange={handleLookup}
                        multiple={true}
                        id={fileConst.RECIPIENTS}
                        defaultValue={
                          formData &&
                          formData.recipients &&
                          formData.recipients.data
                            ? formData.recipients.data
                            : ""
                        }
                        {...props.content.emailList}
                      />
                    ) : (
                      <A_MultiSelect
                        defaultValue=""
                        label="To *"
                        fullWidth={true}
                        onChange={handleSelect}
                        id={fileConst.RECIPIENTS}
                        multiple={true}
                        options={
                          options && options.length > 0
                            ? options[0].options
                            : []
                        }
                        error={
                          validation[fileConst.RECIPIENTS] !== undefined &&
                          validation[fileConst.RECIPIENTS] !== ""
                        }
                        helperText={
                          validation[fileConst.RECIPIENTS]
                            ? validation[fileConst.RECIPIENTS]
                            : ""
                        }
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <A_Autocomplete
                      fullWidth={true}
                      size="medium"
                      entity={{}}
                      variant="standard"
                      className={classes.inputControl}
                      label={"CC "}
                      onChange={handleLookup}
                      multiple={true}
                      id="ccUsers"
                      {...props.content.emailList}
                      {...props.content.emailList}
                      defaultValue={
                        formData && formData.ccUsers && formData.ccUsers.data
                          ? formData.ccUsers.data
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.formGroup}>
                    <TextField
                      className={classes.inputField}
                      id={fileConst.SUBJECT}
                      label="Subject "
                      fullWidth={true}
                      error={
                        validation[fileConst.SUBJECT] !== undefined &&
                        validation[fileConst.SUBJECT] !== ""
                      }
                      helperText={
                        validation[fileConst.SUBJECT]
                          ? validation["subject"]
                          : ""
                      }
                      placeholder=""
                      variant="standard"
                      onChange={subjectEmail}
                      value={formData.subject}
                      required
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <A_SummerNote
                      className={classes.formGroup}
                      label={"Request "}
                      height="80"
                      onChange={handleSummerNote}
                      defaultValue={remark}
                      disabled={false}
                    ></A_SummerNote>{" "}
                  </Grid>
                  <Grid item xs={12} className={classes.formGroup}>
                    <M_FormDropableFileUpload
                      files={files}
                      setFiles={setFileData}
                    />
                  </Grid>
                </Grid>
                <div className="">
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.backButton}
                    type="button"
                    onClick={prePage}
                  >
                    Back{" "}
                  </Button>
                  <Box style={{ float: "right" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.sendMailButton}
                      type="button"
                      onClick={showResult}
                    >
                      Send Mail{" "}
                    </Button>
                  </Box>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        }
        onClose={handleClose}
      />
    </div>
  );
}
function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
    managePage: state.managePage,
    user: state.oidc.user.profile.email,
  };
}
IcEmail.propTypes = {
  user: propTypes.any,
  content: propTypes.any,
  onClose: propTypes.any,
  ircID: propTypes.string,
  currentPage: propTypes.object,
  isOpen: propTypes.bool,
  options: propTypes.any,
};
export default connect(mapStateToProps)(IcEmail);
