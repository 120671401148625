import React,{useState} from "react";
import { Grid, Box, FormControl, InputLabel, Select, FormHelperText,TextField, InputAdornment } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import BackspaceIcon from "@material-ui/icons/Backspace";
import A_IconButton from "../../../Atoms/Inputs/Buttons/A_IconButton";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import PropTypes, { func, string } from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect } from "react";
import { lookUpTypes, sourceTypes } from "./excel.Helper";

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      height : "100%",
      width: "100%",
      marginBottom : '24px' 
    },
    formControlResult: {
      margin: theme.spacing(1),
      height : "100%",
      width: "40%",
      marginBottom : '24px' 
    },
    resultDropDowns: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
}));
export const LookUpView = (props) => {
    
    const [comparisonMetric1, setComparisonMetric1] = useState("");
    const [comparisonMetric2, setComparisonMetric2] = useState(""); 
    const [conditionOp, setConditionOp] = useState(""); 
    const [trueMetric, setTrueMetric] = useState(""); 
    const [falseMetric, setFalseMetric] = useState(""); 
    const classes = useStyles();
    const {
        dropDownValue,
        handleChange,
        showGroupedOptions,
        operators,
        equation,
        payloadEquation,
        incorrectEquation,
        backSpace,
        deleteEquation,
        lookupMetricValue,
        searchMetricName
    } = props; 
    const disManthleEquation = () => {
      let splitedPayloadEquation = payloadEquation.split(" ");
      setComparisonMetric1(searchMetricName(splitedPayloadEquation[0])+" "+splitedPayloadEquation[0]);
      setComparisonMetric2(searchMetricName(splitedPayloadEquation[2])+" "+splitedPayloadEquation[2]);
      setConditionOp(splitedPayloadEquation[1]+" "+splitedPayloadEquation[1]);
      setTrueMetric(searchMetricName(splitedPayloadEquation[4])+" "+splitedPayloadEquation[4]);
      setFalseMetric(searchMetricName(splitedPayloadEquation[6])+" "+splitedPayloadEquation[6]);
    }
    useEffect(() => {
      
      props.selectedLookUp === lookUpTypes.COMPARISON ? disManthleEquation() : "";
    }, [equation]);
    switch(props.selectedLookUp){
        case lookUpTypes.ARITHMETIC_FORMULA : 
        return (
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-required-label">
                      {lookupMetricValue}
                    </InputLabel>
                    <Select
                      native
                      labelId="demo-mutiple-chip-label"
                      value={dropDownValue}
                      onChange={(event) => {
                        handleChange(event, sourceTypes.DROPDOWN);
                      }}
                    >
                      {showGroupedOptions()}
                    </Select>
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={7}>
                  <Grid container spacing={1}>
                    {operators
                      ? operators.map((item) => (
                          <Grid item xs={2} key={item}>
                            <A_Button
                              style={{ marginTop: "1.3rem" }}
                              size="medium"
                              variant="outlined"
                              color="#000"
                              label={item}
                              onClick={(value) => {
                                handleChange(value, sourceTypes.BUTTON);
                              }}
                            />
                          </Grid>
                        ))
                      : ""}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={equation}
                      label={
                        payloadEquation.length > 0
                          ? incorrectEquation
                            ? "Incorrect Equation"
                            : ""
                          : ""
                      }
                      error={payloadEquation.length > 0 ? incorrectEquation : false}
                      // onChange={(event) => {}}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <A_IconButton
                              icon={<BackspaceIcon />}
                              color="#000"
                              onClick={() => {
                                backSpace();
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    ></TextField>
                    <A_IconButton
                      icon={<DeleteIcon />}
                      color="#000"
                      onClick={() => {
                        deleteEquation();
                      }}
                    ></A_IconButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
        );
        case lookUpTypes.COMPARISON:
            return(
            <Box>              
              <Grid container spacing={3}>
                <Grid item xs={5}>
                          <FormControl className={classes.formControl}>
                          <InputLabel id="demo-simple-select-required-label">
                              {"Metric 1"}
                          </InputLabel>
                          <Select
                              native
                              id="firstMetric"
                              labelId="demo-mutiple-chip-label"
                              value={comparisonMetric1}
                              onChange={(event) => {
                                event.target.id = comparisonMetric1 ? comparisonMetric1.split(" ")[comparisonMetric1.split(" ").length-1] : event.target.id; 
                                setComparisonMetric1(event.target.value);
                                handleChange(event, sourceTypes.DROPDOWN, lookUpTypes.COMPARISON);
                              }}
                          >
                              {showGroupedOptions()}
                          </Select>
                          <FormHelperText></FormHelperText>
                          </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-required-label">
                        {"Operator"}
                    </InputLabel>
                    <Select
                        native
                        id="conditionOperator"
                        labelId="demo-mutiple-chip-label"
                        value={conditionOp}
                        onChange={(event) => {
                          event.target.id = conditionOp ? conditionOp.split(" ")[0] : event.target.id; 
                          setConditionOp(event.target.value);
                          handleChange(event, sourceTypes.DROPDOWN, lookUpTypes.COMPARISON);
                        }}
                    >
                      <option key={""} value={""}>{""}</option>  
                      <option key="=" value="= =">{"="}</option>
                      <option key="!=" value="!= !=">{"!="}</option>
                      <option key="<" value="< <">{"<"}</option>
                      <option key=">" value="> >">{">"}</option>
                      <option key="<=" value="<= <=">{"<="}</option>
                      <option key=">=" value=">= >=">{">="}</option>
                    </Select> 
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={5}>
                  <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-required-label">
                      {"Metric 2"}
                  </InputLabel>
                  <Select
                      native
                      id="secondMetric"
                      labelId="demo-mutiple-chip-label"
                      value={comparisonMetric2}
                      onChange={(event) => {
                        event.target.id = comparisonMetric2 ? comparisonMetric2.split(" ")[comparisonMetric2.split(" ").length-1] : event.target.id; 
                        setComparisonMetric2(event.target.value);
                        handleChange(event, sourceTypes.DROPDOWN, lookUpTypes.COMPARISON);
                      }}
                  >
                      {showGroupedOptions()}
                  </Select>
                  <FormHelperText></FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <div className={classes.resultDropDowns}>
                <FormControl className={classes.formControlResult}>
                  <InputLabel id="demo-simple-select-required-label">
                      {"IF True"}
                  </InputLabel>
                  <Select
                      native
                      id="trueMetric"
                      size="small"
                      labelId="demo-mutiple-chip-label"
                      value={trueMetric}
                      onChange={(event) => {
                        event.target.id = trueMetric ? trueMetric.split(" ")[trueMetric.split(" ").length-1] : event.target.id; 
                        setTrueMetric(event.target.value);
                        handleChange(event, sourceTypes.DROPDOWN, lookUpTypes.COMPARISON);
                      }}
                  >
                      {showGroupedOptions()}
                  </Select>
                  <FormHelperText></FormHelperText>
                </FormControl>
              </div>
              <div className={classes.resultDropDowns}>
                <FormControl className={classes.formControlResult}>
                  <InputLabel id="demo-simple-select-required-label">
                      {"IF False"}
                  </InputLabel>
                  <Select
                      native
                      id="falseMetric"
                      size="small"
                      labelId="demo-mutiple-chip-label"
                      value={falseMetric}
                      onChange={(event) => {
                        event.target.id = falseMetric ? falseMetric.split(" ")[falseMetric.split(" ").length-1] : event.target.id; 
                        setFalseMetric(event.target.value);
                        handleChange(event, sourceTypes.DROPDOWN, lookUpTypes.COMPARISON);
                      }}
                  >
                      {showGroupedOptions()}
                  </Select>
                  <FormHelperText></FormHelperText>
                </FormControl>
              </div>
            </Box>);
        case lookUpTypes.CARRY_FORWARD:
            return(
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-required-label">
                            {lookupMetricValue}
                        </InputLabel>
                        <Select
                            native
                            labelId="demo-mutiple-chip-label"
                            value={dropDownValue}
                            onChange={(event) => {
                            handleChange(event, sourceTypes.DROPDOWN, "Carry Forward");
                            }}
                        >
                            {showGroupedOptions()}
                        </Select>
                        <FormHelperText></FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>);
    }
};

LookUpView.defaultProps = {
    openPopup: true,
    content: [],
    tabData: [],
    tabdata : [],
    lookupMetricId: 0,
    lookupMetricInfo : "",
    dropDownValue : "",
    handleChange : func,
    showGroupedOptions : func,
    operators : [],
    equation : "",
    payloadEquation : [],
    incorrectEquation : "",
    backSpace : func,
    deleteEquation : func,
    selectedLookUp : string,
    lookupMetricValue : string,
    searchMetricName : func
  };
  LookUpView.propTypes = {
    openPopup: PropTypes.bool,
    content: PropTypes.array,
    tabData: PropTypes.array,
    tabdata: PropTypes.array,
    lookupMetricId: PropTypes.number,
    handlelookup: PropTypes.func,
    callBack: PropTypes.func,
    lookupMetricInfo : PropTypes.string,
    dropDownValue : PropTypes.string,
    handleChange : PropTypes.func,
    showGroupedOptions : PropTypes.func,
    operators : PropTypes.array,
    equation : PropTypes.string,
    payloadEquation : PropTypes.array,
    incorrectEquation : PropTypes.string,
    backSpace : PropTypes.func,
    deleteEquation : PropTypes.func,
    selectedLookUp : PropTypes.string,
    lookupMetricValue : PropTypes.string,
    searchMetricName : PropTypes.func
  };
