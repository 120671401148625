import PropTypes from "prop-types";
import React, { useEffect } from "react";
import PageContext from "../../../../../contexts/pageContext";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import DisplaySectionHeader from "../../../Molecules/Common/DisplaysectionHeader/DisplaySectionHeader";
import {
  FromEpochFormat,
  customDateFormat,
} from "../../../../../Utils/Helpers/dateUtils";
import M_DropableFileUpload from "../../../Molecules/Common/File Upload/M_DropableFileUpload";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import A_Snackbar from "../../../Atoms/Feedback/Snackbars/A_Snackbar";
import { useParams } from "react-router";
import { httpPost } from "../../../../../Utils/apis/apis";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Grid } from "@material-ui/core";
import "./ViewFields.css";
import * as loggedInUserIdActions from "../../../../../store/actions/loggedInUserIdActions";
import { AddEdge } from "../../../../../Utils/Helpers/crmDataApis/crmDataApis";
import { useSelector } from "react-redux";
import { TableHead, Typography } from "@material-ui/core";
import { DocumentUpload } from "../../../../../Utils/Helpers/documentUploadHelper";
import { getFileName } from "../../../Atoms/DataDisplay/DataGrid/helper/download.helper";
import ViewFieldAsLink from "./ViewFieldAsLink";
import TradeApprovalButtons from "../../../Molecules/TradeApproveButtons/TradeApprovalButtons";
import {
  handleOptionSetValue,
  getIcon,
} from "../../../../../Utils/Helpers/optionSet.helper";
import { getCRMContactInfo } from "../../../../../Utils/Helpers/common";
const ViewFields = (props) => {
  const fields = props.fields ? props.fields : [];
  let { crmId } = useParams();
  const [dealTeamData, setdealTeamData] = React.useState(false);
  const [showButton, setShowButton] = React.useState(false);
  const [listOfFiles, setListOfFiles] = React.useState([]);
  let {crmContactUserId, crmContactUserEmail} = getCRMContactInfo();
  const userid = useSelector((state) =>
    state &&
    state.getLoggedInUserReducer &&
    state.getLoggedInUserReducer.data &&
    state.getLoggedInUserReducer.data[0] &&
    state.getLoggedInUserReducer.data[0].id
      ? state.getLoggedInUserReducer.data[0].id
      : crmContactUserId
  );
  const email = useSelector((state) =>
    state &&
    state.getLoggedInUserReducer &&
    state.getLoggedInUserReducer.data &&
    state.getLoggedInUserReducer.data[0] &&
    state.getLoggedInUserReducer.data[0].properties &&
    state.getLoggedInUserReducer.data[0].properties.email
      ? state.getLoggedInUserReducer.data[0].properties.email
      : crmContactUserEmail
  );
  const useStyles = makeStyles(() => ({
    table: {
      minWidth: "auto",
    },
    Label: {
      fontWeight: "600",
      width: 1000,
      wordBreak: "break-word",
    },
    textLabel: {
      wordBreak: "break-word",
      width: 1000,
    },
    boldText: {
      fontWeight: "600",
    },
    multiSelect: {
      margin: "0px",
      padding: "0px",
    },
    fieldPadding: { padding: "0.4rem" },
    FieldCss: { padding: "0.6rem", textAlign: "end" },
    stringcss: { padding: "0.4rem", textAlign: "end" },
    labelCss: { fontSize: "0.9rem", fontWeight: "500", textAlign: "left" },
    tablecell: { borderBottom: "none" },
    tableHeading: {
      marginTop: "20px",
      color: "#1976D2",
    },
  }));
  const getData = async () => {
    let data = await getFileName(crmId, DocumentUpload.containerType);
    setListOfFiles(data);
  };
  const classes = useStyles();
  useEffect(() => {
    props.syncEdgeLabel ? fetchMyAPI() : "";
    getData();
  }, []);

  async function fetchMyAPI() {
    let convertedFormData = {
      sourceVertexId: crmId,
      edgeLabel: props.syncEdgeLabel,
    };
    let getEdgeRes = await httpPost(
      `${process.env.API_URL}/CRMData/getEdge`,
      convertedFormData
    );

    for (var i = 0; i < getEdgeRes.length; i++) {
      var loggedinID = userid;
      var loggedEmail = email;
      if (crmId == loggedinID || loggedEmail == getEdgeRes[i].createdBy) {
        setdealTeamData(false);
        break;
      } else {
        setdealTeamData(true);
      }
    }
  }

  const [changesSaved, setChangesSaved] = React.useState({
    status: false,
    type: "success",
    msg: "",
  });

  const handleSnackbarClose = () => {
    setChangesSaved({
      status: false,
      type: "",
      msg: "",
    });
  };

  const rowValue = (crmData, field) => {
    try {
      field.rows = field.rows.map((item) =>
        item.fields.map((item2) => getValue(crmData, item2))
      );
    } catch (e) {
      // TODO add error handling here
      // remove console.log
      console.log(e);
    }
    return field;
  };
  const getValue = (crmData, field) => {
    if (field.fieldType != "text") {
      field["value"] = crmData[field.id];
    }
    return field;
  };
  const truncate = (num, type) => {
    let places =
      props &&
      props.CurrentCard &&
      props.CurrentCard.content &&
      props.CurrentCard.content.decimal
        ? props.CurrentCard.content.decimal
        : 2; // TODO for making it field specific  backend change is required. For now its card specific

    if (num && type == "float" && places != "") {
      return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
    } else {
      return num;
    }
  };

  const GetTextField = (props) => {
    let { field, crmData } = props;
    return (
      <TableRow key={field.id}>
        <TableCell component="th" scope="row" className={classes.Label}>
          {field.label}
        </TableCell>
        {field.htmlId ? (
          <TableCell
            id={field.htmlId}
            className={`${classes.textLabel} ${
              field.bold ? classes.boldText : ""
            }`}
          ></TableCell>
        ) : (
          <TableCell
            align={`${field.align || "left"}`}
            className={`${classes.textLabel} ${
              field.bold ? classes.boldText : ""
            }`}
          >
            {getFieldValue(field, crmData)}
          </TableCell>
        )}
      </TableRow>
    );
  };
  const GetCheckboxField = (props) => {
    let { field, crmData } = props;
    return (
      <TableRow key={field.id}>
        <TableCell component="th" scope="row" className={classes.Label}>
          {field.label}
        </TableCell>
        <TableCell
          align={`${field.align || "left"}`}
          className={classes.textLabel}
        >
          {getFieldValue(field, crmData)}
        </TableCell>
      </TableRow>
    );
  };

  const verifyAndGetUrlWithHttp = (field, crmData) => {
    let url = getFieldValue(field, crmData);

    if (url && url.length > 4) {
      url = url.substring(0, 4) === "http" ? url : "http://" + url;
    }

    return url;
  };
  const ButtonAction = async () => {
    setShowButton(true);
    var data = {
      sourceVertexId: props.loggedIn.data[0].id,
      destinationVertexId: crmId, //Seached user,company
      edgeLabel: props.syncEdgeLabel,
      destinationVertexLabel: "contact",
    };
    await AddEdge(data);
    setChangesSaved({
      status: true,
      type: "success",
      msg: "Synced",
    });
  };
  const GetLinkField = (props) => {
    let { field, crmData } = props;
    return (
      <TableRow key={field.id}>
        <TableCell component="th" scope="row" className={classes.Label}>
          {field.label}
        </TableCell>
        <TableCell
          align={`${field.align || "left"}`}
          className={classes.textLabel}
        >
          {field && field.inNewTab ? (
            <a
              style={{ display: "table-cell" }}
              href={verifyAndGetUrlWithHttp(field, crmData)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {getFieldValue(field, crmData)}
            </a>
          ) : (
            <a
              href={verifyAndGetUrlWithHttp(field, crmData)}
              rel="noopener noreferrer"
            >
              {getFieldValue(field, crmData)}
            </a>
          )}
        </TableCell>
      </TableRow>
    );
  };
    const GetHtmlField = (props) => {
      let { field, crmData } = props;
      return crmData[field.id] ? (
        <TableRow key={field.id}>
          <TableCell component="th" scope="row" colSpan={2}>
            <Grid
              style={{
                width: "auto",
                display: "flex",
                justifyContent: "flex-start",
                whiteSpace: "unset",
                wordBreak: "break-word",
              }}
            >
              <div
                className={`${field.bold ? classes.boldText : ""}`}
                id="htmlText"
                style={{
                  position: "relative",
                  overflow: "auto",
                  whiteSpace: "unset",
                }}
                dangerouslySetInnerHTML={{ __html: crmData[field.id] }}
              ></div>
            </Grid>
          </TableCell>
        </TableRow>
      ) : (
        ""
      );
    };
  //handelTableFormat
  const GetTableField = (props) => {
    let { field, crmData } = props;
    field = rowValue(crmData, field);
    return (
      <TableRow>
        <TableCell colSpan={2} className={classes.tablecell}>
          <Grid container>
            <Grid item sm={12}>
              <h5 className={classes.tableHeading}>{field.label}</h5>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {field.head.map((columnHead, index) => (
                      <TableCell
                        key={columnHead}
                        className={
                          index == 0 ? classes.fieldPadding : classes.FieldCss
                        }
                      >
                        {columnHead}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {field && field.rows && Array.isArray(field.rows)
                    ? field.rows.map((item) => (
                        <TableRow key={item}>
                          {field && field.rows && Array.isArray(field.rows)
                            ? item.map((item2) => {
                                let lookUpValue = "";
                                if (item2.fieldType == "lookup") {
                                  let lookUpResult = {};
                                  try {
                                    lookUpResult = JSON.parse(
                                      typeof item2.value === "string"
                                        ? item2.value
                                        : "{}"
                                    );
                                  } catch (e) {
                                    lookUpResult = {};
                                  }
                                  lookUpValue =
                                    lookUpResult &&
                                    lookUpResult[0] &&
                                    lookUpResult[0]["title"]
                                      ? lookUpResult[0]["title"]
                                      : "";
                                }
                                return (
                                  <TableCell
                                    key={item2}
                                    className={`${classes.stringcss} ${
                                      item2.bold ? classes.boldText : ""
                                    }`}
                                    colSpan={
                                      item.length == 2 &&
                                      item2.fieldType != "text" &&
                                      2
                                    }
                                  >
                                    <>
                                      {item2.fieldType == "string" ||
                                      item2.fieldType == "integer" ||
                                      item2.fieldType == "float" ||
                                      item2.fieldType == "optionset"
                                        ? item2.value
                                          ? item2.value != ""
                                            ? truncate(
                                                item2.value,
                                                item2.fieldType
                                              )
                                            : ""
                                          : ""
                                        : ""}
                                      {item2.fieldType == "text" ? (
                                        <>
                                          <Typography
                                            className={classes.labelCss}
                                            varient="subtitle2"
                                          >
                                            {item2 && item2.label
                                              ? item2 && item2.label
                                              : item2 &&
                                                item2.name &&
                                                item2.name}
                                          </Typography>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {item2.fieldType == "lookup"
                                        ? lookUpValue
                                        : ""}
                                    </>
                                  </TableCell>
                                );
                              })
                            : ""}
                        </TableRow>
                      ))
                    : ""}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    );
  };
  const GetDocumentField = (props) => {
    let content = DocumentUpload;
    let { field } = props;
    return (
      <>
        {field.fieldType.toLowerCase() === "document" &&
        field.fieldAsLink === false ? (
          <TableRow key={field.id}>
            <TableCell
              component="th"
              scope="row"
              colSpan={2}
              className={classes.Label}
            >
              {field.label}
              <M_DropableFileUpload
                content={content}
                className={classes.textLabel}
              />
            </TableCell>
          </TableRow>
        ) : field.fieldType.toLowerCase() === "document" &&
          field.fieldAsLink &&
          listOfFiles &&
          listOfFiles.length > 0 ? (
          <TableRow key={field.id}>
            <TableCell
              component="th"
              scope="row"
              // colSpan={2}
              className={classes.Label}
            >
              {field.label}
            </TableCell>
            <TableCell align={"left"}>
              <ViewFieldAsLink
                content={content}
                files={listOfFiles}
                data={props}
                id={crmId}
                FieldId={field.id}
              />
            </TableCell>
          </TableRow>
        ) : (
          ""
        )}
      </>
    );
  };

  const GetButtonField = (props) => {
    let { field } = props;
    return (
      <>
        {dealTeamData ? (
          <TableRow key={field.id}>
            <TableCell
              component="th"
              scope="row"
              colSpan={2}
              className={classes.Label}
            >
              <A_Button
                size="small"
                color="primary"
                label={field.label}
                onClick={ButtonAction}
                disabled={showButton}
              />
            </TableCell>
          </TableRow>
        ) : (
          ""
        )}
      </>
    );
  };

  const GetContentFieldType = (props) => {
    let { field } = props;
    return (
      <>
        {field &&
        field.fieldType &&
        (field.fieldType == "date" ||
          field.fieldType == "percentage" ||
          field.fieldType == "multiple" ||
          field.fieldType == "lookup" ||
          field.fieldType == "optionset" ||
          field.fieldType == "radios" ||
          field.fieldType == "float" ||
          field.fieldType == "integer" ||
          field.fieldType == "multiselect" ||
          field.fieldType == "financial" ||
          field.fieldType == "text") ? (
          <GetTextField {...props} />
        ) : (
          ""
        )}
        {field && field.fieldType && field.fieldType == "hypertext" ? (
          <GetLinkField {...props} />
        ) : (
          ""
        )}
        {field && field.fieldType && field.fieldType == "html" ? (
          <GetHtmlField {...props} />
        ) : (
          ""
        )}

        {field && field.fieldType && field.fieldType == "table" ? (
          <GetTableField {...props} />
        ) : (
          ""
        )}
        {field &&
        field.fieldType &&
        field.fieldType.toLowerCase() === "string" &&
        field.validations.includes("http") ? (
          <GetLinkField {...props} />
        ) : field &&
          field.fieldType &&
          field.fieldType.toLowerCase() === "string" ? (
          <GetTextField {...props} />
        ) : (
          ""
        )}
        {field &&
        field.fieldType &&
        field.fieldType.toLowerCase() === "document" ? (
          <GetDocumentField {...props} />
        ) : (
          ""
        )}
        {field && field.fieldType && field.fieldType == "bit" ? (
          <GetCheckboxField {...props} />
        ) : (
          ""
        )}
        {field && field.fieldType && field.fieldType == "section" ? (
          <TableCell component="th" scope="row" colSpan={2}>
            <DisplaySectionHeader {...props} label={field.label} />
          </TableCell>
        ) : (
          ""
        )}
        {field &&
        field.fieldType &&
        field.fieldType.toLowerCase() === "button" ? (
          <GetButtonField {...props} />
        ) : (
          ""
        )}
      </>
    );
  };
  const getFieldValue = (field, crmData) => {
    let multipleLabel = "";
    let percentLabel = "";
    let val = "";
    if (field.fieldType == "bit") {
      val = crmData[field.id] === true ? "Yes" : "No";

      return val;
    }
    if (field.fieldType == "optionset") {
      let data = crmData[field.id];
      data = handleOptionSetValue(data, false);
      let optionSet =
        props.currentPageConfig &&
        props.currentPageConfig.optionSets &&
        props.currentPageConfig.optionSets
          ? props.currentPageConfig.optionSets.find(
              (item) => item.Id == field.optionSet
            )
          : "";

      let optionSetItem =
        optionSet && optionSet.options.find((item) => item.value == data);
      val = optionSetItem && optionSetItem.label ? optionSetItem.label : "";
      return val;
    }
    if (field.fieldType == "multiple") {
      multipleLabel = field.id;
    }

    if (field.fieldType == "percentage") {
      percentLabel = field.id;
    }
    if (field.fieldType == "lookup") {
      let data = crmData[field.id];
      let convertData = [];
      if (data != "" && data != undefined) {
        convertData = JSON.parse(data);
      }
      return (
        <>
          {convertData.map((item) => {
            return (
              <p className={classes.multiSelect} key={item}>
                {item && item.title ? (
                  field.inLink ? (
                    <a href={`${item.label}/${item.id}/`}>{item.title}</a>
                  ) : (
                    item.title
                  )
                ) : (
                  item.emailAddress && item.emailAddress.address
                )}
              </p>
            );
          })}
        </>
      );
    }

    //handelTableFormat
    if (field.fieldType == "multiselect") {
      let data = crmData[field.id];
      let convertData = [];
      if (data != "" && data != undefined) {
        convertData = JSON.parse(data);
      }
      return (
        <>
          {convertData.map((item) => {
            return (
              <p className={classes.multiSelect} key={item}>
                {item}
              </p>
            );
          })}
        </>
      );
    }

    if (field.fieldType == "date") {
      if (crmData && crmData[field.id] !== undefined) {
        let dateValue = FromEpochFormat(crmData[field.id]);
        dateValue =
          typeof dateValue === "object" ? dateValue.toString() : dateValue;
        return customDateFormat(dateValue, field.formatType);
      }
    }

    if ("value" in field || crmData === undefined || crmData === null) {
      val = field.value;
    } else {
      if (percentLabel && crmData[field.id] !== undefined) {
        if (crmData[field.id] !== "") {
          val = crmData[field.id] + "%";
        }
      } else if (multipleLabel && crmData[field.id] !== undefined) {
        if (crmData[field.id] !== "") {
          val = crmData[field.id] + "x";
        }
      } else {
        val = crmData[field.id];
      }
    }
    if (field.fieldType == "float" && val != "") {
      return truncate(val, field.fieldType);
    }
    //Handle finacial Fields
    if (field.fieldType == "financial") {
      if (crmData && (crmData.currency || crmData.Currency)) {
        return getIcon(crmData, val);
      } else {
        return val;
      }
    }
    return val;
  };
  return (
    <PageContext.Consumer>
      {({ crmData }) => {
        return (
          <>
            <div>
              <Table
                // className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableBody>
                  {fields &&
                    fields.length > 0 &&
                    fields.map((field) => {
                      return (
                        <GetContentFieldType
                          field={field}
                          crmData={crmData}
                          key={field}
                        />
                      );
                    })}
                </TableBody>
              </Table>
              <A_Snackbar
                open={changesSaved.status}
                message={changesSaved.msg}
                type={changesSaved.type}
                autoHideDuration={2000}
                vertical="bottom"
                horizontal="center"
                handleClose={handleSnackbarClose}
              />
            </div>
            {props && props.content && props.content.approvalButtons ? (
              <TradeApprovalButtons
                {...props}
                files={listOfFiles}
                complianceOfficer={props.content.complianceOfficer}
                id={crmId}
                FieldName={props.content.fieldIdForApprovalButtons}
                callback={getData}
              />
            ) : (
              ""
            )}
          </>
        );
      }}
    </PageContext.Consumer>
  );
};
function mapStateToProps(state) {
  return {
    loggedIn: state.getLoggedInUserReducer,
    currentPageConfig: state.currentPage.pageConfig,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, loggedInUserIdActions), dispatch);
}

ViewFields.defaultProps = {
  fields: [],
  content: {},
  crmData: {},
  field: {},
  syncEdgeLabel: "",
  loggedinID: "",
  loggedIn: {},
};
ViewFields.propTypes = {
  fields: PropTypes.array,
  content: PropTypes.object,
  crmData: PropTypes.object,
  field: PropTypes.object,
  getLoggedInUser: PropTypes.func,
  syncEdgeLabel: PropTypes.string,
  loggedinID: PropTypes.id,
  loggedIn: PropTypes.object,
  CurrentCard: PropTypes.any,
  currentPageConfig: PropTypes.any,
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewFields);
