import React from "react";
import M_ToolbarFilter from "./M_ToolbarFilter";
import { useParams } from "react-router";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { addToolbarFilter } from "../../../../../../store/actions/manageToolbarSelectionsActions";

const M_ToolToolbarFilterContainer = (props) => {
  const [pageFilters, setPageFilters] = React.useState(undefined);
  const { templateId, crmId } = useParams();
  const toolbar = props.toolbar;
  const optionSets = props.optionSets;
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const dispatch = useDispatch();
  
  const dispatchFilters = (selectedoption) => {
    setSelectedOptions((prevState) => [...prevState, selectedoption]);
    if(Object.keys(selectedoption).length > 0 && pageFilters.length -1 === selectedOptions.length){
      dispatch(addToolbarFilter([...selectedOptions, selectedoption]));
    }
    else if(pageFilters.length === selectedOptions.length){
      let filteredSelectedOptions = [...(selectedOptions.filter((ele) => ele.filterName !== selectedoption.filterName)), selectedoption];
      setSelectedOptions((prevState) => [...(prevState.filter((ele) => ele.filterName !== selectedoption.filterName)), selectedoption]);
      dispatch(addToolbarFilter(filteredSelectedOptions));
    }

  }

 
  React.useEffect(() => {
    dispatch(addToolbarFilter([]));
    setSelectedOptions([]);
    if (toolbar && toolbar.primary && toolbar.primary.filters) {
      const inScopeFilters = toolbar.primary.filters.filter(
        (item) => item.templateIds && item.templateIds.indexOf(templateId) > -1
      );
      if (inScopeFilters && inScopeFilters.length > 0) {
        setPageFilters(inScopeFilters);
      } else {
        setPageFilters(undefined);
      }
    } else {
      setPageFilters(undefined);
    }
  }, [templateId]);

  var ScopeFilters = [];
   ScopeFilters =
    toolbar &&
    toolbar.primary &&
    toolbar.primary.filters &&
    toolbar.primary.filters.filter(
       (item) => item.templateIds && item.templateIds.indexOf(templateId) > -1
     );
  return (
    <React.Fragment key={templateId}>
    
      {toolbar &&
        ScopeFilters &&
         ScopeFilters.length > 0 &&
            pageFilters &&
            pageFilters.map((pageFilter, index) => {
              return (
                <M_ToolbarFilter
                  key={pageFilter.ptionSetName}
                  dispatchFilters={dispatchFilters}
                  pageFiltersLength={pageFilters.length}
                  indexValue={index}
                  toolbar={toolbar}
                  {...pageFilter}
                  optionSets={optionSets}
                  templateId={templateId}
                  investmentId={crmId}
                />
              );
            })}
       
        </React.Fragment>

  );
};

M_ToolToolbarFilterContainer.propTypes = {
  toolbar: PropTypes.object,
  optionSets: PropTypes.array
};
export default M_ToolToolbarFilterContainer;
