import React from "react";
import { useState, useRef } from "react";
import axios from "axios";
import A_Snackbar from "../../../Atoms/Feedback/Snackbars/A_Snackbar";
import { useParams } from "react-router";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import CircularDeterminate from "../../../Atoms/Inputs/CircularProgressBar/CircularProgressBar";
import { CloudUpload } from "@material-ui/icons";
import { imageGalleryEnums } from "./imageGallery.Helper";
const M_ImageGalleryUpload = (props) => {
  const inputFile = useRef(null);
  const [hover, setHover] = useState(false);  
  const [uploading, setUploading] = useState(false)
  const user = useSelector((state) => state.oidc.user);
  const [changesSaved, setChangesSaved] = React.useState({
    status: false,
    type: "success",
    msg: "",
  });
 
  let { crmId } = useParams();

  const handleSnackbarClose = () => {
    setChangesSaved({
      status: false,
      type: "",
      msg: "",
    });
  };

  const handlefile =(event) => {
    let filesList = event.target.files
    if(filesList && filesList.length > 1){
      for(let i =0 ; i <filesList.length;  i++ ){
        uploadFile(event, crmId,user, filesList[i], props.content,true,i,filesList.length-1);
     }
    }else{
      uploadFile(event, crmId, user, filesList, props.content) 
    }     
    event.target.value = ''   
  }

  const handleClick = () => {   
    inputFile.current.click();
  };

  const handleDropFiles = (event) => {
    stopEvent(event);
    setHover(false);
    const { files } = event.dataTransfer;
    if(files.length > 1){
      for(let i =0 ; i <files.length;  i++ ){
        uploadFile(event, crmId,user, files[i], props.content,true,i,files.length-1);
     }
    }else{
      uploadFile(event, crmId,user, files, props.content);
    }           
  };

  const uploadFile= async (e=undefined,crmId, user, dropedFiles=undefined, content=undefined,multiPle=false,index=undefined,fileLength=undefined)=>{
    setUploading(true)
    let resultFiles = [];
    let filesList = dropedFiles ? dropedFiles : e.target.files;
    let fileName;
   if(multiPle){
    fileName =
    crmId +  imageGalleryEnums.GALLERY + filesList.name;
   }else{
    for (let file of filesList) {
      fileName =
      crmId +  imageGalleryEnums.GALLERY + file.name;
      resultFiles.push(file);
    }
   } 

    if (content.allowedExtensions !== undefined )
    {
    const  ext = fileName.split(".").pop();
    if(content.allowedExtensions.includes(ext.toLowerCase())){
    let reader = new FileReader();
    reader.readAsDataURL(multiPle ? filesList : resultFiles[0]);
    reader.onload = (e) => {
      let subdomain = new URL(window.location.href).hostname.split(".")[0];
      {
        const url = `${process.env.DOCUMENT_HELPER}`;
        let header = {
          filename:  fileName,
          type:content.containerType,
          "file-operation": "upload",
          "process-file": content.processFile ? content.processFile : "no",
          "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
          subdomain: subdomain,
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.access_token,
        };
        const formData = { file: e.target.result };
        axios
          .post(url, formData, { headers: header }) 
          .then(
            (response) => {              
                let messgae =
                response && response.message
                  ? response.message
                  : imageGalleryEnums.UPLOAD_SUCESSFULY;
              if(multiPle && index && fileLength && index == fileLength){
                setChangesSaved({
                  status: true,
                  type: "success",
                  msg: messgae,
                });
                setUploading(false)
                props.setStatus(Math.floor(Math.random()*(999-100+1)+100))
              }
              if(!multiPle){
                setChangesSaved({
                  status: true,
                  type: "success",
                  msg: messgae,
                });
                setUploading(false)
                props.setStatus(Math.floor(Math.random()*(999-100+1)+100))
              }
                    
            },
            (error) => {
              let messgae = error && error.message ? error.message : "Error";
              setChangesSaved({
                status: true,
                type: "error",
                msg: messgae,
              });
              setUploading(false)
            }
          );      
      }
    }}else{
      setUploading(false)
   alert("file not supported")
    }
    }
  }

  const stopEvent = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onDragOver = (event) => {
    stopEvent(event);
    setHover(true);
  };
  const onDragLeave = (event) => {
    stopEvent(event);
    setHover(false);
  };

  return (
    <div>
      <section style={{ minWidth: "20px" }}>
        <div
          className={
            hover ? "drop-zone-container hover" : "drop-zone-container"
          }
          onDrop={handleDropFiles}
          onDragLeave={onDragLeave}
          onDragOver={onDragOver}
        >
          <CloudUpload color="primary" />
          {!uploading ? (
            <p style={{ color: "#054af7" }}>
              Drag or Drop Files here to upload{" "}
            </p>
          ) : (
            ""
          )}
          {uploading ? <CircularDeterminate /> : ""}
          {!uploading ? (
            <A_Button
              onClick={handleClick}
              onDrop={handleDropFiles}
              onDragLeave={onDragLeave}
              onDragOver={onDragOver}
              color="primary"
              label="Click to Upload"
            />
          ) : (
            ""
          )}
          <input           
            className={"fileinput"}
            type="file"
            ref={inputFile}
            onChange={handlefile}
            multiple
          />
        </div>
       
        <A_Snackbar
          open={changesSaved.status}
          message={changesSaved.msg}
          type={changesSaved.type}
          autoHideDuration={2000}
          horizontal="center"
          handleClose={handleSnackbarClose}
        />      
      </section>     
    </div>
  );
};

M_ImageGalleryUpload.propTypes = {
  setStatus: PropTypes.func,
  content:PropTypes.object
};

export default M_ImageGalleryUpload;
