/* eslint-disable */
export const currentPageActionTypes = {
  GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
  GET_CURRENT_PAGE_SUCCESSED: "GET_CURRENT_PAGE_SUCCESSED",
  GET_CURRENT_PAGE_FAILED: "GET_CURRENT_PAGE_FAILED",
};
export const pageTemplatesActionTypes = {
  GET_PAGE_TEMPLATES: "GET_PAGE_TEMPLATES",
  GET_PAGE_TEMPLATES_SUCCESSED: "GET_PAGE_TEMPLATES_SUCCESSED",
  GET_PAGE_TEMPLATES_FAILED: "GET_PAGE_TEMPLATES_FAILED",
};

export const managePageActionTypes = {
  GET_PAGE: "GET_PAGE",
  GET_PAGE_SUCCESSED: "GET_PAGE_SUCCESSED",
  GET_PAGE_FAILED: "GET_PAGE_FAILED",
  RESET_MANAGE_PAGE: "RESET_MANAGE_PAGE",
};
export const manageExcelTemplateActionType = {
  GET_TABS: "GET_TABS",
  GET_TABS_SUCCESSED: "GET_TABS_SUCCESSED",
  GET_TABS_FAILED: "GET_TABS_FAILED",
  SET_TABS: "SET_TABS",
  SAVE_TABS: "SAVE_TABS",
  SAVE_TABS_SUCCESSED: "SAVE_TABS_SUCCESSED",
  SAVE_TABS_FAILED: "SAVE_TABS_FAILED",
  DOWNLOAD_EXCEL: "DOWNLOAD_EXCEL",
  DOWNLOAD_EXCEL_SUCCESSED: "DOWNLOAD_EXCEL_SUCCESSED",
  DOWNLOAD_EXCEL_FAILED: "DOWNLOAD_EXCEL_FAILED",
};
export const manageExcelTemplateDownloadActionType = {
  DOWNLOAD_EXCEL: "DOWNLOAD_EXCEL",
  DOWNLOAD_EXCEL_SUCCESSED: "DOWNLOAD_EXCEL_SUCCESSED",
  DOWNLOAD_EXCEL_FAILED: "DOWNLOAD_EXCEL_FAILED",
};
export const manageMenusActionType = {
  GET_MENUS: "GET_MENUS",
  GET_MENUS_SUCCEEDED: "GET_MENUS_SUCCEEDED",
  GET_MENUS_FAILED: "GET_MENUS_EXCEL_FAILED",
  SET_URL: "SET_URL",
};
export const entityRecordActionType = {
  GET_ENTITY_RECORD: "GET_ENTITY_RECORD",
  GET_ENTITY_RECORD_SUCCEEDED: "GET_ENTITY_RECORD_SUCCEEDED",
  GET_ENTITY_RECORD_FAILED: "GET_ENTITY_RECORD_FAILED",
};
export const esgRecordActionType = {
  GET_ESG_RECORD: "GET_ESG_RECORD",
  GET_ESG_RECORD_BY_ID:"GET_ESG_RECORD_BY_ID",
  GET_ESG_RECORD_SUCCEEDED: "GET_ESG_RECORD_SUCCEEDED",
  GET_ESG_RECORD_FAILED: "GET_ESG_RECORD_FAILED",
  INSERT_ESG_RECORD:"INSERT_ESG_RECORD",
  INSERT_ESG_RECORD_SUCCEEDED:"INSERT_ESG_RECORD_SUCCEEDED",
  INSERT_ESG_RECORD_FAILED:"INSERT_ESG_RECORD_FAILED",
  UPDATE_ESG_RECORD:"UPDATE_ESG_RECORD",
  UPDATE_ESG_RECORD_SUCCEEDED:"UPDATE_ESG_RECORD_SUCCEEDED",
  UPDATE_ESG_RECORD_FAILED:"UPDATE_ESG_RECORD_FAILED"
  
};

export const manageToolbarSelectionsActionType = {
  ADD_FILTER: "ADD_FILTER",
  RESET_FILTERS: "RESET_FILTERS",
};
export const checklistActionTypes = {
  GET_CHECKLIST: "GET_CHECKLIST",
  GET_CHECKLIST_SUCCEEDED: "GET_CHECKLIST_SUCCEEDED",
  GET_CHECKLIST_FAILED: "GET_CHECKLIST_FAILED",
};
export const loggedInUserIdActionTypes = {
  GET_LOGGED_IN_USER_TYPE: "GET_LOGGED_IN_USER_TYPE",
  GET_LOGGED_IN_USER_TYPE_SUCCEEDED: "GET_LOGGED_IN_USER_TYPE_SUCCEEDED",
  GET_LOGGED_IN_USER_TYPE_FAILED: "GET_LOGGED_IN_USER_TYPE_FAILED",
};
export const manageFormActionTypes = {
  SET_EDIT_FORM: "SET_EDIT_FORM",
};
export const toastActionTypes = {
  SET_ALERT_STATUS: "SET_ALERT_STATUS",
};
export const previousUploadActionTypes = {
  GET_PREVIOUS_UPLOAD: "GET_PREVIOUS_UPLOAD",
  GET_PREVIOUS_UPLOAD_SUCCEEDED: "GET_PREVIOUS_UPLOAD_SUCCEEDED",
  GET_PREVIOUS_UPLOAD_FAILED: "GET_PREVIOUS_UPLOAD_FAILED",
  GET_PREVIOUS_UPLOAD_RESET:"GET_PREVIOUS_UPLOAD_RESET"
};
export const previousUploadDeleteActionTypes = {
  GET_PREVIOUS_UPLOAD_DELETE: "GET_PREVIOUS_UPLOAD_DELETE",
  GET_PREVIOUS_UPLOAD_DELETE_SUCCEEDED: "GET_PREVIOUS_UPLOAD_DELETE_SUCCEEDED",
  GET_PREVIOUS_UPLOAD_DELETE_FAILED: "GET_PREVIOUS_UPLOAD_DELETE_FAILED",
};
export const pptDownloadActionTypes = {
  GET_PPT_DOWNLOAD:"GET_PPT_DOWNLOAD",
  GET_PPT_DOWNLOAD_SUCCEEDED: "GET_PPT_DOWNLOAD_SUCCEEDED",
  GET_PPT_DOWNLOAD_FAILED: "GET_PPT_DOWNLOAD_FAILED",
}

export const getIrcSlotActionTypes = {
  GET_IRC_SLOT: "GET_IRC_SLOT",
  GET_IRC_SLOT_SUCCEEDED: "GET_IRC_SLOT_SUCCEEDED",
  GET_IRC_SLOT_FAILED: "GET_IRC_SLOT_FAILED",
};

export const dealTeamActionType = {
  DEL_DEAL_TEAM_EDGE:"DEL_DEAL_TEAM_EDGE",
  DEL_DEAL_TEAM_EDGE_SUCCEEDED: "DEL_DEAL_TEAM_EDGE_SUCCEEDED",
  DEL_DEAL_TEAM_EDGE_FAILED:"DEL_DEAL_TEAM_EDGE_FAILED",
  GET_DEAL_TEAM_EDGE:"GET_DEAL_TEAM_EDGE",
  GET_DEAL_TEAM_EDGE_SUCCEEDED: "GET_DEAL_TEAM_EDGE_SUCCEEDED",
  GET_DEAL_TEAM_EDGE_FAILED:"GET_DEAL_TEAM_EDGE_FAILED",
};

export const manualUpdateActionType = {
  GET_DROPDOWN_VALUE: "GET_DROPDOWN_VALUE",
  GET_DROPDOWN_VALUE_SUCCESSED: "GET_DROPDOWN_VALUE_SUCCESSED",
  GET_DROPDOWN_VALUE_FAILED: "GET_DROPDOWN_VALUE_FAILED",
  GET_DT_VALUE: "GET_DT_VALUE",
  SET_DT_VALUE_SUCCESSED: "SET_DT_VALUE_SUCCESSED",
  SET_DT_DROPDOWN_VALUE_FAILED: "SET_DT_VALUE_FAILED",  
};