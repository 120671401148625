/* eslint-disable */
import { take, put, select, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as manualActions from "../actions/manualAction";
import { manualUpdateActionType } from "../actions/actionTypes";
import * as commonFunctions from "../../Utils/Helpers/common";
import { ErrorMsg } from "../../Utils/config/config";

let config = commonFunctions.getHttpConfig();
let apiUrl = `${process.env.API_URL}/ChartData/getchartdata`;

export function* getManualUpdateSaga() {
  const action = yield take(manualUpdateActionType.GET_DROPDOWN_VALUE);
  const { crmId, pageUrl, getMetricDropdownChartId } = action.payload;
  const params = {
    parameters: {
      investment: "0",
    },
    content: { sourceId: `${crmId}`, sourceLabel: `${pageUrl}` },
    chartId: getMetricDropdownChartId,
  };
  try {
    let res = yield axios.post(apiUrl, params, config);
    yield put(
      manualActions.getDropdownValueSucceeded({
        data: res.data.data,
        stateUpdated: true,
      })
    );
  } catch (e) {
    manualActions.getDropdownValueFailed({
      msg: ErrorMsg.ERROR,
      stateUpdated: false,
    });
    console.log(e.message);
  }
}

export function* getMetricDataTableSaga() {
  yield takeEvery(manualUpdateActionType.GET_DT_VALUE, getMetricDataTable);
}

export function* getMetricDataTable(action) {
  const { crmId, pageUrl, metricId, getMetricDataTableChartId } =
    action.payload;
  const params = {
    parameters: {
      investment: crmId,
      metric: metricId,
    },
    content: { sourceId: crmId, sourceLabel: pageUrl },
    chartId: getMetricDataTableChartId,
  };
  try {
    let res = yield axios.post(apiUrl, params, config);
    yield put(
      manualActions.getMetricDTValueSucceeded({
        data: res.data.data,
        stateUpdated: true,
      })
    );
  } catch (e) {
    manualActions.getMetricDTValueFailed({
      msg: ErrorMsg.ERROR,
      stateUpdated: false,
    });
    console.log(e.message);
  }
}
